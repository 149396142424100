<template>
  <div>
    <Register v-if="form.config.template == 'register'" :view="view" :formLink="formLink" />
    <Standard v-else :view="view" :formLink="formLink" />
  </div>
</template>

<script>

import Standard from "./templates/Standard.vue";
import Register from "./templates/Register.vue";

export default {
  name: 'edgeryders-form',
  data: () => ({
    form: null
  }),
  methods: {
    parseJson(value) {
      var code = value.match(/```([^`]*)```/);
      if (code) {
        return JSON.parse(code[1]);
      } else {
        return false;
      }
    },
    getFormRemote(id) {
      var self = this;
      fetch("https://edgeryders.eu/raw/" + id + ".json")
      .then((response) => {
        response.text().then(function(text) {
          self.form = self.parseJson(text);
        });
      })
      .catch((error) => console.error(error));
    }
  },
  created() {
    this.getFormRemote(this.formLink);
  },
  components: {
    Register,
    Standard
  },
  props: ["view", "formLink"]
}
</script>

<style lang="scss">

</style>