<template>
  <div class="cards" v-resize="onResize" v-if="items.length">
    <div class="cards_text" v-if="view.title || view.text">
      <div class="cards_title title">{{ view.title }}</div>
    </div>
    <div class="row">
      <div class="row_options">
        <div class="row_navigation" v-if="items.length >= card_count">
          <button class="toggle previous" @click="previous()"></button>
          <button class="toggle next" @click="next()"></button>
        </div>
      </div>
      <div class="row_wrapper" v-if="items">
        <Card
          v-for="(item, index) in items.slice(
            card_index,
            card_index + card_count
          )"
          class="item"
          :parallax="parallax"
          :data="item"
          :key="index + Math.random()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/elements/Card.vue";

export default {
  name: "GridView",
  data() {
    return {
      items: null,
      remain: null,
      activeColor: "red",
      topic_text: null,
      parallax: "depth",
      dynamic: "Test",
      card_count: 5,
      card_index: 0,
      viewport: null,
    };
  },
  props: ["view"],
  components: {
    Card,
  },
  methods: {
    getTopics(tag) {
      var self = this;
      fetch("https://edgeryders.eu/tag/" + tag + ".json")
        .then((response) => response.json())
        .then((data) => {
          var array = data.topic_list.topics.map((x) => self.createItem(x));
          self.items = array;
        });
    },
    createItem(item) {
      var obj = {
        title: item.title,
        image: item.image_url,
        url: "https://edgeryders.eu/t/" + item.id,
        likes: item.like_count,
      };
      return obj;
    },
    onResize({ width, height }) {
      if (width < 500) {
        this.viewport = "mobile";
        this.card_count = this.items.length;
        this.parallax = false;
      }
    },
    previous() {
      if (this.card_index - this.card_count < 0) {
        this.card_index = this.card_count;
      } else {
        this.card_index = this.card_index - this.card_count;
      }
    },
    next() {
      var next_count = this.card_index + this.card_count;
      var remain = this.items.length - next_count;
      this.remain = remain;

      if (remain < 0) {
        this.card_index = 0;
      } else {
        this.card_index = this.card_index + this.card_count;
      }
    },
  },
  mounted() {
    var width = window.innerWidth;
    if (width < 600) {
      this.viewport = "mobile";
      this.card_count = 100;
      this.parallax = "false";
    }
    if (this.view.config.tag) {
      this.getTopics(this.view.config.tag);
    }
  },
};
</script>

<style lang="scss">
@for $i from 1 to 10 {
  .row_wrapper .card:nth-child(#{$i}) {
    animation-delay: $i * 0.125s !important;
  }
}
.cards {
  max-width: 100%;
  position: relative;
  .cards_text {
    width: 80%;
    line-height: 1.75rem;
    font-size: 1.2rem;
    margin: 0 auto 1.5rem;
  }
}
.cards .row {
  width: 100%;
  position: relative;
  justify-content: flex-start;

  .row_options {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    top: 0px;

    align-items: center;
    .row_navigation {
      display: inline-flex;
      @apply pt-4;

      width: 100%;
      .toggle {
        width: 40px;
        border-radius: 100px;
        height: 40px;
        background-size: 13px !important;
        transition: all 0.2s ease;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 40'%3E%3Cpath stroke='black' stroke-width='2' d='M9.6 1.6c-.2 0-.3 0-.4.2-.2.2-.2.5 0 .8l12.1 12.1c.7.7.7 1.9 0 2.6L9.2 29.4c-.2.3-.2.6 0 .8l.4.2c.1 0 .3 0 .4-.2L22 18.1c1.2-1.2 1.2-3 0-4.2L10 1.8a.6.6 0 00-.4-.2z'/%3E%3C/svg%3E");
        background-position: 50% 60%;
        background-color: #efefef;
        background-repeat: no-repeat !important;
        &:hover {
          background-color: #ff6a10;
        }
        &.previous {
          background-position: 45% 60% !important;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 40'%3E%3Cpath stroke='black' stroke-width='2' d='M22.4 1.6c-.1 0-.3 0-.4.2L10 13.9a2.9 2.9 0 000 4.2L22 30.2l.4.2c.2 0 .3 0 .4-.2.2-.2.2-.5 0-.8L10.7 17.3c-.7-.7-.7-1.9 0-2.6L22.8 2.6c.2-.3.2-.6 0-.8a.6.6 0 00-.4-.2z'/%3E%3C/svg%3E");
          margin-right: 10px;
        }
        &:hover {
          cursor: pointer !important;
          transform: scale(1.05);
        }
        &:active {
          transform: scale(1, 1) !important;
        }
      }
    }
  }
  .row_wrapper {
    width: 100%;
    margin-top: 20px;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .item {
    display: inline-block;
    border-radius: 10px;
    position: relative;
    float: left;
    background-size: cover !important;
    max-width: 230px;
    flex: 1;
    height: 350px;
    overflow: hidden;
    opacity: 0;
    animation: fadein 0.85s ease forwards;
  }

  .item .item_info {
    height: 82%;
    width: 85%;
    position: absolute;
    border-radius: 10px;
    bottom: 0;
    display: block;
    padding: 8%;
    background: rgba(0, 0, 0, 0.8);
    transform: translateY(100%);
    transition: all 0.4s ease;
    h2 {
      font-size: 1rem;
      margin: 0.5rem 0;
    }
    p {
      font-size: 0.8rem;
    }
  }

  .item:hover .item_info {
    transform: translateY(0%);
    color: white;
  }
}

@media only screen and (max-width: 600px) {
  .row {
    width: auto;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    .row_wrapper {
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      display: flex;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      .item {
        scroll-snap-align: start;
        width: 60vw !important;
        height: 400px;
        margin-right: 1rem;
        flex-shrink: 0 !important;
        flex: auto;
      }
    }
    .row_options {
      display: none !important;
    }
    .item {
      width: 500px !important;
    }
  }
}
</style>
