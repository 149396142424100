<template>
  <div class="text_wrapper" :class="[{'single': content.length == 1 }, config && config.class]" :style="config && config.style">
    <div v-if="title" class="text_title" v-html="title"></div>
    <div :class="{'horizontal': content.length > 1 }">
      <component class="text_content" v-for="(test, index) in content" v-bind:is="parse(test)" :key="index"></component>
      <div v-if="topic_text" v-html="topic_text" class="topic"></div>
    </div>
    <button v-for="item in buttons" :class="item.class" :style="item.style" @click="trigger(item)">{{item.text}}</button>
  </div>
</template>

<script>
import User from "@/components/elements/User.vue";
import Vue from 'vue';
import { bus } from "@/main";

Vue.component('User', User);

export default {
  name: "TextView",
  data() {
    return {
      topic_text: null,
      dynamic: 'Test'
    }
  },
  components: {
    User
  },
  props: {
    title: String,
    text: Array,
    links: Array,
    buttons: Array,
    config: Object,
    video: Object
  },
  methods: {
    trigger(item) {
      if (item.section) {
        bus.$emit("scrollTo", item.section);
      }
      if (item.form) {
        var obj = {
          "type": "form",
          "value": item.form
        }
        bus.$emit("openModal", obj);
      }
    },
    parse(text) {
      var match = text.match(/\B@[a-z0-9_-]+/gim);
      console.log(match)
      if (match) {
        var username = text.match(/\B@[a-z0-9_-]+/gim)[0];
        function getUserComponent(match, offset, string) {
          return '<User username="' + match + '"></User>'
        }

        var html = text.replace(/\B@[a-z0-9_-]+/gim, getUserComponent);
  

        return {
            template: '<div>' + html + '</div>'
        }
      } else {
        return {
            template: '<div>' + text + '</div>'
        }
      }
    }
  },
  created(){
    this.content = this.text;
    if (this.config && this.config.topic) {
      fetch('https://edgeryders.eu/t/' + this.config.topic + '.json')
      .then(response => response.json())
      .then(data => {
        this.topic_text = data.post_stream.posts[0].cooked;
      })
    }
  }
};
</script>

