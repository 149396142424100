var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"relative"},[_c('div',{staticClass:"carousel",on:{"mouseenter":_vm.stopRotation,"mouseleave":_vm.startRotation}},_vm._l((_vm.slides.slice(_vm.slideIndex, _vm.slideIndex + _vm.number)),function(slide,index){return _c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
        followCursor: true,
        offset: '10, 10',
        touch: false,
        theme: 'light',
        placement: 'left',
        arrow: true,
        arrowType: 'round',
        animation: 'shift-away',
      }),expression:"{\n        followCursor: true,\n        offset: '10, 10',\n        touch: false,\n        theme: 'light',\n        placement: 'left',\n        arrow: true,\n        arrowType: 'round',\n        animation: 'shift-away',\n      }"}],key:slide,ref:"newton",refInFor:true,staticClass:"slide_el-md",class:{ active: _vm.paused },style:(_vm.view.config.style),attrs:{"content":`<div class='tooltip_custom font-bold text-lg'>${slide.text}</div>`},on:{"click":function($event){return _vm.activate(index)}}},[_c('h2',[_vm._v(_vm._s(slide.title))])])}),0),_c('div',{staticClass:"navigation"},[_c('div',{class:{ paused: _vm.paused },attrs:{"id":"load_container"}},[(_vm.showLoader)?_c('div',{attrs:{"id":"circle-loader-wrap"}},[_vm._m(0),_vm._m(1)]):_vm._e()]),_c('div',{staticClass:"dots"},_vm._l((Math.floor(_vm.slides.length / _vm.number) + 1),function(i,index){return _c('div',{staticClass:"indicator",class:{ active: _vm.slideIndex / _vm.number == index },on:{"click":function($event){return _vm.setIndex(index)}}})}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-wrap"},[_c('div',{staticClass:"loader"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-wrap"},[_c('div',{staticClass:"loader"})])
}]

export { render, staticRenderFns }