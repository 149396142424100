<template>
  <div class="relative" v-resize="onResize">
    <div
      class="carousel"
      @mouseenter="stopRotation"
      @mouseleave="startRotation"
    >
      <div
        v-for="(slide, index) in slides.slice(slideIndex, slideIndex + number)"
        :key="slide"
        class="slide_el-md"
        :class="{ active: paused }"
        @click="activate(index)"
        :content="
          `<div class='tooltip_custom font-bold text-lg'>${slide.text}</div>`
        "
        v-tippy="{
          followCursor: true,
          offset: '10, 10',
          touch: false,
          theme: 'light',
          placement: 'left',
          arrow: true,
          arrowType: 'round',
          animation: 'shift-away',
        }"
        ref="newton"
        :style="view.config.style"
      >
        <h2>{{ slide.title }}</h2>
      </div>
    </div>

    <div class="navigation">
      <div id="load_container" :class="{ paused: paused }">
        <div id="circle-loader-wrap" v-if="showLoader">
          <div class="left-wrap">
            <div class="loader"></div>
          </div>
          <div class="right-wrap">
            <div class="loader"></div>
          </div>
        </div>
      </div>

      <div class="dots">
        <div
          v-for="(i, index) in Math.floor(slides.length / number) + 1"
          class="indicator"
          :class="{ active: slideIndex / number == index }"
          @click="setIndex(index)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slides: null,
      showImage: false,
      showLoader: true,
      tooltip: false,
      number: 4,
      slideIndex: 0,
      paused: false,
      timer: null,
      active: null,
    };
  },
  methods: {
    getAsset(file) {
      return require("@/assets/" + file);
    },
    showTip(index) {
      var self = this;
      this.$nextTick(() => self.$refs.newton[index]._tippy.show());
    },
    hideTip(index) {
      var self = this;
      this.$nextTick(() => self.$refs.newton[index]._tippy.hide());
    },
    activate(index) {
      if (this.active == null) {
        this.active = index;
      } else {
        this.active = null;
      }
    },
    close() {
      this.active = null;
    },
    isActive() {
      if (this.active !== null) {
        return true;
      } else {
        return false;
      }
    },
    setIndex(value) {
      this.slideIndex = value * this.number;
      this.showLoader = false;
      this.stopRotation();
      setTimeout(() => (this.showLoader = true), 1);
      this.startRotation();
    },
    next() {
      if (this.slideIndex + 1 >= this.slides.length) {
        this.slideIndex = 0;
      } else {
        this.slideIndex = this.slideIndex + this.number;
      }
    },
    stopRotation() {
      clearInterval(this.timer);
      this.paused = true;
    },
    startRotation() {
      this.timer = setInterval(this.next, 3000);
      this.paused = false;
    },
    onResize({ width, height }) {
      if (width < 500) {
        this.viewport = "mobile";
        this.number = 1;
      } else {
        this.viewport = "desktop";
        this.number = 4;
        this.tooltip = false;
      }
    },
  },
  mounted() {
    var width = window.innerWidth;
    if (width < 500) {
      this.viewport = "mobile";
      this.number = 1;
    } else {
      this.viewport = "desktop";
      this.number = 4;
      this.tooltip = true;
    }

    this.startRotation();
  },
  computed: {},
  props: ["slides", "view"],
};
</script>
<style lang="scss">
.light-theme {
  color: #26323d;
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15),
    0 4px 80px -8px rgba(36, 40, 47, 0.25),
    0 4px 4px -2px rgba(91, 94, 105, 0.15);
  background-color: #fff !important;
}
.light-theme[x-placement^="top"] .tippy-arrow {
  border-top: 8px solid #fff;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}
.light-theme[x-placement^="bottom"] .tippy-arrow {
  border-bottom: 8px solid #fff;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}
.tippy-tooltip.light-theme[x-placement^="left"] .tippy-arrow {
  border-left: 8px solid #fff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}
.light-theme[x-placement^="right"] .tippy-arrow {
  border-right: 8px solid #fff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}
.light-theme .tippy-backdrop {
  background-color: #fff;
}
.light-theme .tippy-roundarrow {
  fill: #fff;
}
.light-theme[data-animatefill] {
  background-color: initial;
}

.tippy-tooltip {
  /* Your styling here. Example: */
  background-color: white !important;
  border: none !important;
}

.carousel {
  top: 10px;
  width: 100%;
  position: relative;
  display: inline-block;
}

.tooltip_custom {
  background: #fff !important;
  padding: 12px;
  margin: 2px 0;
  border-radius: 4px;
  text-align: left;
  color: black !important;
  h2 {
    @apply font-bold text-base border-b pb-2 mb-2;
  }
  p {
    font-size: 13px;
  }
}

.slide_el.active {
  opacity: 1 !important;
  animation: none !important;
  background: white;
}

.image {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  z-index: 1000;
  overflow: hidden;
}
@keyframes bgFade {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes textFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.slide_el:hover {
  cursor: help;
  background: #fafafa;
}
.learn {
  background: none;
  @apply flex items-center justify-center;
  .learn_slider {
    .slide_el {
      &:last-child {
        margin-right: 0;
      }
      h2 {
        @apply font-bold ml-4;
      }
    }
    .info {
      @apply w-full bg-white absolute top-0 h-full;
      transform: translateX(100%);
      transition: transform 0.3s ease;
      .info_text {
        @apply p-4;
        font-size: 14px;
      }
      &.active {
        transform: translateX(0%);
      }
    }
  }
}

@for $i from 0 to 5 {
  .carousel div:nth-child(#{$i}) {
    animation-delay: $i * 0.085s !important;
  }
}
.learn .wrapper {
  @apply flex flex-col pb-6;
  width: 100%;
}
.learn h1 {
  @apply font-bold text-2xl mb-4;
  color: blue;
}
.learn .wrapper-md {
  @apply px-8;
  width: 80% !important;
}

.head h1 {
  @apply p-2 px-3 mb-3 w-auto text-xl font-bold;
  background: #0034c9;
  color: white;
}

.crowdfund .wrapper-md h1 {
  width: 90%;
}

.slide_el-md {
  @apply border mb-2 flex items-center float-left;
  width: 22%;
  animation: bgFade 0.75s ease forwards;
  height: 200px;
  opacity: 0;
  background: white;

  border-radius: 4px;
  margin-right: 3%;
  margin-bottom: 3%;
}

.learn_slider {
  @apply w-full relative pb-4 mt-3 border;
  height: 300px;
  overflow-x: hidden;
}

.learn_slider-md {
  @apply flex w-full;
  height: 220px;
  background: none;
  border: none;
  overflow-x: visible;
}

.learn_slider .list_el {
  @apply absolute w-full h-full bg-white;
  overflow-y: scroll;
  background: none;
  transition: transform 0.3s ease;
}

.list_el-md {
  @apply flex relative;
}

.slide_el-md {
  @apply flex;
  height: 200px;
}

.list_el.active {
  transform: translateX(-100%);
}

.slide_el {
  @apply w-full flex items-center;
  border-bottom: 1px solid #efefef;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  height: 60px;
}
.slide_el-md h2 {
  @apply pl-4 text-lg font-bold;
  width: 50%;
  animation: textFade 1s ease forwards;
}

.learn_slider {
  @apply w-full relative pb-4 mt-3 border;
  height: 300px;
  overflow-x: hidden;
}

.learn_slider-md {
  @apply flex w-full;
  height: 220px;
  background: none;
  border: none;
  overflow-x: visible;
}

.learn_slider .list_el {
  @apply absolute w-full h-full bg-white;
  overflow-y: scroll;
  background: none;
  transition: transform 0.3s ease;
}

@media only screen and (max-width: 600px) {
  .carousel {
    width: 93%;
    margin: 0 auto;
  }
  .slide_el-md {
    width: 100%;
    h2 {
      width: 90%;
    }
  }
}

.navigation {
  @apply flex items-center w-full pt-3 pl-2;
  .dots {
    @apply flex;
    .indicator {
      background: #ddd;
      border: 1px solid #fff;
      width: 10px;
      height: 10px;
      border-radius: 100px;
      margin-right: 10px;
      &.active {
        background: #ff6a10;
        transform: scale(1.2);
      }
    }
  }
}

#load_container {
  width: 24px;
  height: 24px;
  margin-right: 1rem;
  &.paused {
    #circle-loader-wrap {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 125'%3E%3Cpath fill='%23fff' d='M18 77c0 7 7 11 13 8l47-28a9 9 0 000-14L31 15c-6-3-13 1-13 8z' overflow='visible'/%3E%3C/svg%3E")
        55% 63% no-repeat #ff6a10;
      transform: rotate(0deg);
      background-size: 11px;
    }
    #circle-loader-wrap .left-wrap .loader {
      animation: none !important;
    }
    #circle-loader-wrap .right-wrap .loader {
      animation: none !important;
    }
  }
}
#load_container #circle-loader-wrap {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1) inset;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 200px;
  transform: rotate(180deg);
}

#load_container #circle-loader-wrap div {
  overflow: hidden;
  position: absolute;
  width: 50%;
  height: 100%;
}
#load_container #circle-loader-wrap .loader {
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  background-color: #ddd;
}
#load_container #circle-loader-wrap .left-wrap {
  left: 0;
}
#load_container #circle-loader-wrap .left-wrap .loader {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transform-origin: 0 50% 0;
  animation: loading-left 3s infinite linear;
}
#load_container #circle-loader-wrap .right-wrap {
  left: 50%;
}
#load_container #circle-loader-wrap .right-wrap .loader {
  left: -100%;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  transform-origin: 100% 50% 0;
  animation: loading-right 3s infinite linear;
}
@keyframes loading-left {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes loading-right {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
</style>
