var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"bodyContainer",attrs:{"id":"app"}},[_c('vue-headful',{attrs:{"title":'Edgeryders'}}),_c('div',{staticClass:"wk_menu"},[_c('div',{staticClass:"menu_bg"},[_c('div',{staticClass:"blur",class:{ disable_blur: _vm.getProp(_vm.config, 'header.blur') == false },style:(_vm.get('menu').style)}),(_vm.getProp(_vm.config, 'header.image'))?_c('div',{staticClass:"header_image",style:([
          {
            background: 'url(' + _vm.config.header.image + ')',
            height: _vm.config.header.height,
            backgroundPosition: _vm.config.header.position,
            opacity: _vm.config.header.opacity,
          },
        ])}):_vm._e()]),_c('span',{staticClass:"menu_logo"},[(_vm.getProp(_vm.config, 'menu.edgeryders.url'))?_c('div',[_c('Menu',{attrs:{"type":_vm.config.menu.edgeryders.icon || 'classic',"color":_vm.config.menu.edgeryders.color || 'white'}})],1):_c('div',[_c('Menu',{attrs:{"type":"classic","color":"white"}})],1),(_vm.getProp(_vm.config, 'menu.other'))?_c('a',{attrs:{"href":_vm.config.menu.other.url}},[_c('img',{staticClass:"other_logo",style:({ height: _vm.config.menu.other.size }),attrs:{"src":_vm.config.menu.other.icon}})]):_vm._e(),(_vm.get('menu').title)?_c('h2',[_vm._v(_vm._s(_vm.get("menu").title))]):_vm._e()]),_c('div',{staticClass:"menu_links",class:{ nofade: _vm.getProp(_vm.config, 'menu.fade') == false }},[(_vm.getProp(_vm.config, 'menu.links.anchor') !== false)?_c('div',{staticClass:"anchor"},_vm._l((_vm.getNavElements(_vm.blocks)),function(link,index){return _c('a',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll",value:({ duration: 1000, offset: -50 }),expression:"{ duration: 1000, offset: -50 }"}],attrs:{"href":link.url}},[_vm._v(" "+_vm._s(link.text)+" ")])}),0):_vm._e(),_vm._m(0),(_vm.get('menu').links.length)?_c('div',{staticClass:"external"},_vm._l((_vm.get('menu').links),function(link,index){return _c('a',{key:index,class:link.class,style:(link.style),attrs:{"href":link.url}},[_vm._v(_vm._s(link.text))])}),0):_vm._e()]),_c('MobileMenu')],1),_c('div',{class:{ modal: _vm.activeModal },style:('top : -' + _vm.windowTop + 'px')},[_c('div',{staticClass:"section header",staticStyle:{"background-color":"rgb(28, 30, 153)"}},[(_vm.get('header').config.video)?_c('div',{staticClass:"bg_video"},[_c('video',{attrs:{"autoplay":"","muted":"","loop":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.get('header').config.video}})])]):_vm._e(),(_vm.getProp(_vm.config, 'header.image'))?_c('div',{staticClass:"header_image",style:([
          {
            background: 'url(' + _vm.config.header.image + ')',
            height: _vm.config.header.height,
            backgroundPosition: _vm.config.header.position,
            opacity: _vm.config.header.opacity,
          },
        ])}):_vm._e(),_c('div',{staticClass:"wrapper",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"text_wrapper single",staticStyle:{"color":"white","position":"relative","width":"50%","flex-shrink":"0","margin-right":"40px"}},[_c('div',{staticClass:"text_title"},[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.get('header').views[0].title)}})]),_c('div',{staticClass:"text-2xl md:text-3xl md:mb-10",domProps:{"innerHTML":_vm._s(_vm.get('header').views[0].text[0])}}),_c('div',{staticClass:"flex flex-col md:flex-row"},_vm._l((_vm.get('header').views[0].buttons),function(item,index){return _c('div',{key:index,attrs:{"target":"_blank"}},[(item.url)?_c('a',{staticClass:"action text-center block font-bold",style:(item.style),attrs:{"href":item.url,"target":"_blank"}},[_vm._v(" "+_vm._s(item.text))]):_vm._e(),(item.section)?_c('a',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll",value:({ duration: 1000, offset: -150 }),expression:"{ duration: 1000, offset: -150 }"}],staticClass:"action text-center block font-bold",style:(item.style),attrs:{"href":'#' + item.section.toLowerCase().replaceAll(' ', '-')}},[_vm._v(_vm._s(item.text))]):_vm._e()])}),0)]),_c('video',{staticStyle:{"width":"50%"},attrs:{"src":"https://edgeryders.eu/uploads/default/original/2X/1/1337da0da528466f0ccc5094265694a50a9ded88.mp4","type":"video/mp4","muted":"","controls":""},domProps:{"muted":true}})])]),_vm._l((_vm.blocks),function(block,index){return _c('div',{key:index},[(_vm.type(block) == 'section')?_c('div',{staticClass:"section content",style:(block.section.style),attrs:{"id":block.section.config && block.section.config.id}},[(block.section.title)?_c('div',{staticClass:"title",class:{
            single:
              block.section.views.length == 1 &&
              block.section.views[0].text.length == 1,
          }},[_c('h2',{attrs:{"id":block.section.title.toLowerCase().replaceAll(' ', '-')}},[_vm._v(" "+_vm._s(block.section.title)+" ")]),(block.section.subtitle)?_c('h3',{domProps:{"innerHTML":_vm._s(block.section.subtitle)},on:{"click":_vm.handleClicks}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"views"},_vm._l((block.section.views),function(view,index){return _c('div',{key:index,staticClass:"view",class:view.type},[(view.type == 'title')?_c('div',{staticClass:"title_view"},[_c('h2',{domProps:{"innerHTML":_vm._s(view.title)}}),_c('p',{domProps:{"innerHTML":_vm._s(view.text)}})]):_vm._e(),(view.type == 'image')?_c('div',{style:({ flexBasis: _vm.config.width })},[_c('img',{attrs:{"src":view.image.url}})]):_vm._e(),(view.type == 'text')?_c('TextView',{attrs:{"title":view.title,"text":view.text,"config":view.config}}):_vm._e(),(view.type == 'form')?_c('Form',{attrs:{"view":view}}):_vm._e(),(view.type == 'grid')?_c('Grid',{attrs:{"view":view}}):_vm._e(),(view.type == 'cards')?_c('Cards',{attrs:{"view":view}}):_vm._e(),(view.type == 'slider')?_c('Slider',{attrs:{"view":view}}):_vm._e(),(view.type == 'carousel')?_c('Carousel',{attrs:{"view":view}}):_vm._e(),(view.type == 'table')?_c('List',{attrs:{"view":view}}):_vm._e(),(view.type == 'blog')?_c('Blog',{attrs:{"view":view}}):_vm._e()],1)}),0)]):_vm._e()])}),_c('Footer',{ref:"footer",attrs:{"config":_vm.get('footer').config}}),(_vm.modals)?_c('p',[_vm._v(_vm._s(_vm.modals))]):_vm._e(),(_vm.modal !== null)?_c('Modal',{attrs:{"type":_vm.modal.type,"value":_vm.modal.value},on:{"close":function($event){return _vm.closeModal()}}}):_vm._e()],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"dropbtn"}),_c('div',{staticClass:"dropdown-content"},[_c('a',{attrs:{"href":"http://remote.edgeryders.eu","target":"_blank"}},[_vm._v("English")]),_c('a',{attrs:{"href":"http://remote.edgeryders.eu/14726","target":"_blank"}},[_vm._v("Français")]),_c('a',{attrs:{"href":"http://remote.edgeryders.eu/14757","target":"_blank"}},[_vm._v("Italiano")])])])
}]

export { render, staticRenderFns }