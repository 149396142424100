<template>
  <div class="profile_container">
    <div class="profile_header">
      <img :src="data.image" />
      <div class="profile_info">
        <div class="profile_title">
          <h3>{{data.title}} <a class="username" :href="'https://edgeryders.eu/u/' + data.username" target="_blank">@{{data.username}}</a></h3> 
        </div>
        <p class="since"><span>Joined</span> {{user.since | formatDate}}</p>
        <a class="message" :href="sendMessage()" target="_blank">Send a message</a>
      </div>
    </div>

    <div class="profile_content">
      <div class="profile_text">{{data.text}}</div>
      <div class="sidebar" v-if="data.skills">
        <h3>Expertise</h3>
        <ul>
          <li v-for="(skill, index) in data.skills">{{skill}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "profile",
  props: ['data'],
  data: function() {
    return {
      user: null
    };
  },
  components: {
  },
  methods: {
    sendMessage() {
      var recipient = this.data.username;
      var url = location.href;
      if (this.data.title) {
        recipient = this.data.title;
      }
      var link =
        "https://edgeryders.eu/new-message?username=" +
        this.data.username +
        "&title=hey%20" +
        recipient +
        "!&body=I saw you mentioned [here](" +
        url +
        ") and wanted to get in touch.";
      return link;
    }
  },
  created(){
    if (this.data.username) {
      var self = this;
      fetch("https://edgeryders.eu/u/" + this.data.username + ".json")
      .then((response) => response.json())
      .then((user) => {
        var obj = {};
        obj['avatar'] =
          "https://edgeryders.eu/" +
          user.users[0].avatar_template.replace("{size}", 200);
        if (user.user.bio_cooked) {
          obj['bio'] = user.user.bio_cooked.replace(
            /<[^\/>][^>]*>\s*<\/[^>]+>/gim,
            ""
          );
        }
        obj['since'] = user.user.created_at;
        obj['account'] = user;
        self.user = obj;
      });
    }
  },
  filters: {
    formatDate: function(value) {
      return moment(value).fromNow();
    },
  }
};
</script>

<style scoped lang="scss">
.profile_header {
  @apply flex w-full;
  img {
    width: 130px;
    height: 130px;
    border-radius: 10px;
    @apply mr-4;
  }
  .profile_info {
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
    @apply px-1;
    h3 {
      @apply text-2xl font-bold border-b pb-1 block mb-1;
    }
    a.username {
      color: orangered;
      @apply ml-1 mr-3;
      font-weight: 400;
    }
    a.message {
      color: orangered;
      background: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='envelope' class='svg-inline--fa fa-envelope fa-w-16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='orangered' d='M502 191c4-3 10 0 10 5v204c0 27-21 48-48 48H48c-26 0-48-21-48-48V196c0-5 6-8 10-5l154 114c21 15 56 47 92 47s72-33 92-47l154-114zM256 320c23 0 57-29 73-41 133-97 143-105 174-129 6-5 9-12 9-19v-19c0-26-22-48-48-48H48C22 64 0 86 0 112v19c0 7 3 14 9 19 31 24 41 32 174 129 16 12 50 41 73 41z'/%3E%3C/svg%3E") no-repeat 12px center;
      border: 1.5px solid orangered;
      font-size: 13px;
      @apply px-3 py-2 mt-4 font-bold;
      padding-left: 36px;
      background-size: 17px;
      border-radius: 15px;
    }
  }
}

.profile_content {
  @apply flex;
}

.profile_text {
  width: 100%;
  @apply pt-6 leading-normal;
}

p.since {
    @apply font-bold;

}

.sidebar {
  background: #000;
  color: white;
  padding: 1.5rem 1.6rem;
  width: 70%;
  @apply ml-8 mt-4 rounded-lg;
  h3 {
    @apply font-bold text-xl border-b border-white pb-2 mb-3;
  }
  ul {
    list-style-type: square;
    @apply pl-6;
    li {

    }
  }
}
</style>
