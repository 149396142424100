<template>
    <div class="md:hidden">
        <div class="mobile_menu_icon" @click="toggleMenu" :class="{ active: mobileMenu }">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="mobileMenu" :class="{ active: mobileMenu }">
            <div class="mobile_menu_container">
                <div class="page_links sections">
                  <a href="#Our Services" @click="toggleMenu()" class="mobile-nav-item" v-smooth-scroll="{ duration: 1000, offset: -50 }">Our Services </a>
                  <a href="#Our Team"  @click="toggleMenu()" class="mobile-nav-item" v-smooth-scroll="{ duration: 1000, offset: -50 }">Our Team </a>
                  <a @click="toggleMenu()" href="#Introduction" class="mobile-nav-item" v-smooth-scroll="{ duration: 1000, offset: -50 }">Introduction </a>
                  <a @click="toggleMenu()" href="#Get In Touch" class="mobile-nav-item" v-smooth-scroll="{ duration: 1000, offset: -50 }">Get In Touch </a></div>
                <div class="page_links languages">
                    <h3>Languages</h3><a   @click="toggleMenu()" href="http://remote.edgeryders.eu" class="mobile-nav-item">English</a><a href="http://remote.edgeryders.eu/14726" class="mobile-nav-item">Français</a><a href="http://remote.edgeryders.eu/14757" class="mobile-nav-item">Italiano</a>
                </div>
                <div class="page_links social">
                    <h3>Share this</h3><a href="https://twitter.com/intent/tweet?text=undefined - https://remote.edgeryders.eu/" target="_blank" class="mobile-nav-item twitter">Twitter</a><a href="https://www.facebook.com/sharer/sharer.php?u=https://remote.edgeryders.eu/" target="_blank" class="mobile-nav-item facebook">Facebook</a><a href="https://www.linkedin.com/sharing/share-offsite/?url=https://remote.edgeryders.eu/" target="_blank" class="mobile-nav-item linkedin">LinkedIn</a><a href="mailto:?subject=undefined&amp;body= https://remote.edgeryders.eu/" target="_blank" class="mobile-nav-item email">Email</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Menu",
    props: {},
    data() {
        return {
            mobileMenu: false,
        };
    },
    methods: {
        toggleMenu() {
            this.mobileMenu = !this.mobileMenu
        }
    },
};
</script>
<style scoped lang="scss">
.page_links {
  @apply flex flex-col py-3;
  a {
    @apply text-black py-5 border-b border-gray-400 text-lg;
  }
}

@keyframes showMenu {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.mobileMenu {
    position: absolute;
    top: 0;
    left: 0;
    padding: 60px 40px;
    width: 100%;
    height: 100vh;
    overflow: scroll;
    background: #ddd;
    display: none;
      &.active {
    display: block;
    animation: showMenu 0.45s ease forwards;
  }
  &.close {
    animation: showMenu 0.45s ease reverse forwards;
  }
}

h3 {
  @apply text-black font-bold text-xl border-b pb-3 border-gray-400;
}

.mobile_menu_icon {
    position: relative;
    z-index: 999999;

    span {
        background: white;
    }
}

.mobile_menu_icon {
    width: 25px;
    display: inline-block;
    height: 30px;
    margin-top: 10px;
    margin-right: 20px;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 999999;

    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: white;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2) {
            top: 9px;
        }

        &:nth-child(3) {
            top: 9px;
        }

        &:nth-child(4) {
            top: 18px;
        }
    }

    &.active {
        span {
            &:nth-child(1) {
                top: 9px;
                width: 0%;
                left: 50%;
            }

            &:nth-child(2) {
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: 9px;
                width: 0%;
                left: 50%;
            }
        }
    }
}
</style>