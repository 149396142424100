<template>
  <a class="card" :href="data.url" target="_blank" v-resize="onResize">
    <kinesis-container style="height: 100%">
      <kinesis-element strength="10" :type="parallax" style="height: 100%">
        <div class="container">
          <div class="front" :style="{ background: 'url(' + data.image + ')' }">
            <div class="card_info">
              <h2>{{ data.title }}</h2>
            </div>
          </div>
        </div>
      </kinesis-element>
    </kinesis-container>
  </a>
</template>

<script>
import moment from "moment";
export default {
  name: "Card",
  props: {
    data: Object,
    parallax: Boolean,
  },
  data: function() {
    return {
      flipped: false,
    };
  },
  methods: {
    flipCard() {
      this.flipped = !this.flipped;
    },
  },
};
</script>

<style scoped lang="scss">
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bgfade {
  0% {
    background: #efefef;
  }
  100% {
    opacity: #fff;
  }
}
.card {
  border-radius: 10px;
  padding: 15px 2% 15px 0;
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .card_info {
    width: 85%;
  }
  h2 {
    display: inline;
    padding: 10px;
    margin: 0.75rem;
    line-height: 2.4rem;
    @apply text-2xl font-bold;
    box-decoration-break: clone;
    background: white;
    color: black;
  }
}

.container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
}

.front,
.back {
  height: 100%;
  display: inline-flex;
  align-items: center;
  width: 100%;
  @apply border;
  background: rgb(29, 30, 153) !important;
  border-radius: 10px;
  transition: transform 0.6s cubic-bezier(0.4, 0.2, 0.2, 1);
  backface-visibility: hidden;
}

.front {
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  background-size: cover !important;
  background-position: 50% 50% !important;
}

.back {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  background: #fff !important;
  transform: rotateY(180deg);
  transform-style: preserve-3d;
  overflow: hidden;
  .text {
    overflow: hidden;
    padding-top: 1.75rem;
    padding-left: 8%;
    padding-right: 8%;
    font-size: 0.8rem;
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  .read_more {
    position: relative;
    background: white;
    bottom: 30px;
    margin: 0 0 0 1rem;
    width: auto;
    border: 0.5px solid orangered;
    color: orangered;
    border-radius: 20px;
    padding: 8px 12px !important;
    text-decoration: none;
    font-weight: normal;
    font-size: 0.7rem;
  }
}

.card {
  &.active .front {
    transform: rotateY(-180deg);
    transform-style: preserve-3d;
  }
  &.active .back {
    transform: rotateY(0deg);
    transform-style: preserve-3d;
  }
}

@media only screen and (max-width: 600px) {
  .card {
    .back {
      @apply flex items-start justify-center;
      padding: 10%;

      .text {
        width: 100% !important;
        padding: 0;
        overflow: visible;
        height: auto;
        flex-grow: 0;
        @apply text-base inline-block font-bold leading-tight;
      }
      a.read_more {
        display: inline-block;
        bottom: 0;
        margin: 1rem 0 0 !important;
        @apply font-bold;
        border: 1px solid orangered;
      }
    }
  }
}
</style>
